<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-text>
        <v-alert border="top" colored-border type="warning" elevation="1">
          Es necesario actualizar la aplicación para continuar utilizándola.
        </v-alert>

        Versión instalada: {{ this.appVersion }} <br />
        Versión mínima: {{ this.minVersion }} <br />
        Versión disponible: {{ this.lastVersion }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          link
          to="googlechrome://app.controltss.com.ar/RelojWebApp.apk"
          color="primary"
        >
          <v-icon left>mdi-download</v-icon>Descargar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { version } from "../../package";
import RelojService from "../services/RelojService.js";
export default {
  name: "UpdateMsg",

  data: () => ({
    appVersion: version,
    versiones: null,
  }),
  props: ["lastVersion", "minVersion"],
  created() {
    if (this.appVersion >= this.minVersion) {
      this.$router.push({
        name: "Ingreso",
      });
    }
  },
  methods: {},
};
</script>
